<template>
  <Layout>
    <div class="ppcontainer regcontainer">
      <Header
        :back="true"
        regulation="pipl"
      />
      <h1
        class="title"
        :dir="dir"
      >
        第三方信息共享清单
      </h1>
      <div class="text notitle">
        <p>(最后更新于2024年06月05日)</p>
        <p>为保障奥飞特七游戏的稳定运行并实现相关功能，我们可能会同合作的第三方共享您的个人信息。在与第三方共享您的个人信息时，我们将督促该第三方按照我们的要求采取适当的安全技术和管理措施，以保护您的个人信息安全。</p>

        <p>我们可能会通过使用第三方提供的服务或接入第三方提供的软件工具开发包（SDK）的形式与第三方共享您的个人信息。我们会对第三方的数据安全保障能力及/或软件工具开发包（SDK）进行严格的安全监测，以保护您的个人信息安全。</p>

        <p>我们将本清单中披露与奥飞特七游戏存在信息共享关系的主体名称、个人信息的使用目的、个人信息的处理方式、个人信息的数据类型及该第三方的隐私政策或官网链接。您可以在以下清单中查看奥飞特七游戏中第三方SDK的信息及共享情况，也可以通过该第三方的隐私政策或官网链接查看其收集和使用个人信息的规则。</p>
      </div>
      <div class="text notitle">
        <br>
        <p><strong>华为云</strong></p>
        <p>第三方主体：华为云计算技术有限公司</p>
        <p>使用目的：实名验证结果存储、游戏及用户数据存储</p>
        <p>
          数据类型：UID、设备标识符（如IDFA/AAID）、游戏进度、游戏内购情况、网络状态、IP地址，语言、地区设置、浏览器插件类型和版本、用户代理字符串（UA）、商店平台、SDK版本、时间戳以及设备技术信息（设备型号和名称、操作系统名称和版本、CPU信息、内存大小、屏幕尺寸、硬件、软件、电话运营商、互联网服务提供商、应用的API密钥标识符、夜间模式、音量、字体大小、电源模式、音量）、IMSI、传感器信息（加速度传感器、陀螺仪传感器）、应用运行情况、使用频率、崩溃和错误、安装情况、性能数据、充值时间、充值金额等
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://www.huaweicloud.com/declaration/sa_prp.html"
            text="https://www.huaweicloud.com/declaration/sa_prp.html"
          />
        </p>

        <br>
        <p><strong>谷歌云</strong></p>
        <p>第三方主体：Google LLC、Google Ireland LTD</p>
        <p>使用目的：储存游戏及用户数据</p>
        <p>
          数据类型：UID、设备标识符（如IDFA/AAID）、游戏进度、游戏内购情况、网络状态、IP地址，语言、地区设置、浏览器插件类型和版本、用户代理字符串（UA）、商店平台、SDK版本、时间戳以及设备技术信息（设备型号和名称、操作系统名称和版本、CPU信息、内存大小、屏幕尺寸、硬件、软件、电话运营商、互联网服务提供商、应用的API密钥标识符、夜间模式、音量、字体大小、电源模式、音量）、IMSI、传感器信息（加速度传感器、陀螺仪传感器）、应用运行情况、使用频率、崩溃和错误、安装情况、性能数据、充值时间、充值金额等
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://cloud.google.com/?hl=zh-cn"
            text="https://cloud.google.com/?hl=zh-cn"
          />
        </p>

        <br>
        <p><strong>Akamai</strong></p>
        <p>第三方主体：Akamai Technologies</p>
        <p>使用目的：传输游戏及用户数据</p>
        <p>
          数据类型：UID、设备标识符（例如IDFA/AAID）、游戏进度、游戏内购情况、网络状态、IP地址，语言、地区设置、浏览器插件类型和版本、用户代理字符串（UA）、商店平台、SDK版本、时间戳以及设备技术信息（设备型号和名称、操作系统名称和版本、CPU信息、内存大小、屏幕尺寸、硬件、软件、电话运营商、互联网服务提供商、应用的API密钥标识符、夜间模式、音量、字体大小、电源模式、音量）、IMSI、传感器信息（加速度传感器、陀螺仪传感器）、应用运行情况、使用频率、崩溃和错误、安装情况、性能数据、充值时间、充值金额等
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://www.akamai.com/zh/legal/compliance/privacy-trust-center"
            text="https://www.akamai.com/zh/legal/compliance/privacy-trust-center"
          />
        </p>

        <br>
        <p><strong>Bugsnag SDK</strong></p>
        <p>第三方主体：SmartBear Software Inc.</p>
        <p>使用目的：排查崩溃问题、帮助APP提升稳定性</p>
        <p>
          数据类型：崩溃数据、配置数据、浏览器数据、设备标识信息、构建数据、设备标识符（IDFA/Mac地址）、地理位置信息（IP地址/Wifi信息/GPS定位）、网络服务器类型/版本、PHP版本、数据库类型和版本、cookie
          信息、设备信息、浏览信息、引用域、目标域、目标路径等
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink

            link="https://smartbear.com/privacy/"
            text="https://smartbear.com/privacy/"
          />
        </p>

        <br>
        <p><strong>Bugly SDK</strong></p>
        <p>第三方主体：深圳市腾讯计算机系统有限公司</p>
        <p>使用目的：排查崩溃问题，帮助APP提升稳定性</p>
        <p>
          数据类型：设备信息（设备型号/设备品牌/系统版本/API等级/CPU架构/设备存储空间/磁盘大小/sdard空间/内存空间/是否root）、网络类型、设备运行状态、设备标识信息（Android
          ID/IMEI/IDFV/IDFA）、日志信息、地区编码、联网信息
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56"
            text="https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56"
          />
        </p>

        <br>
        <p><strong>Appsflyer SDK</strong></p>
        <p>第三方主体：AppsFlyer Ltd.</p>
        <p>使用目的：广告监测、归因与反作弊</p>
        <p>
          数据类型：设备信息（浏览器类型、设备类型和型号、CPU、系统语言、内存、操作系统版本、Wi-Fi
          状态、时间戳，设备运动参数）、设备标识符（IDFA等）、UID、IP地址、广告信息（点击数、展示频次、数据来源、广告类型、引用的URL等）、应用程序列表
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://www.appsflyer.com/legal/services-privacy-policy/"
            text="https://www.appsflyer.com/legal/services-privacy-policy/"
          />
        </p>

        <br>
        <p><strong>热云 SDK</strong></p>
        <p>第三方主体：北京热云科技有限公司及其关联公司</p>
        <p>使用目的：广告监测、归因与反作弊</p>
        <p>
          数据类型：iOS广告标识符（IDFA和IDFV）、安卓广告主标识符（Android
          ID）、网卡地址（MAC）、国际移动设备识别码（IMEI）、移动设备识别码（MEID）、匿名设备标识码（OAID）、时区、APP包名、APP版本、系统版本、监测事件发生时间、运营商信息、设备型号、设备系统信息、设备硬件信息、设备运行环境信息、设备制造厂商、网络连接类型、设备操作系统版本、iOS设备是否已开启限制广告追踪、iOS设备是否已越狱、设备传感器信息、IP地址、互联网广告标识（CAID）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="ttps://www.reyun.com/privacyPolicy/"
            text="ttps://www.reyun.com/privacyPolicy/"
          />
        </p>

        <br>
        <p><strong>巨量转化 SDK</strong></p>
        <p>第三方主体：北京巨量引擎网络技术有限公司及其关联方</p>
        <p>使用目的：广告监测、归因与反作弊</p>
        <p>
          数据类型：设备信息（设备品牌、型号、软件系统版本相关信息、OAID、设备IMEI、AndroidID、MEID、硬件序列号、系统属性、操作系统等基础信息，IDFA、IDFV、设备品牌、型号、软件系统版本相关信息、手机系统重启时间、磁盘总空间、系统总内存空间、CPU数目、系统属性、操作系统等）、应用信息 （开发者应用名、应用包名、版本号等信息）、运营商信息、设备时区、无线网SSID名称、WIFI路由器MAC地址、设备的mac地址、IMSI、ICCID、WIFI状态、转化事件
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://open.oceanengine.com/labels/7/docs/1708428054592516"
            text="https://open.oceanengine.com/labels/7/docs/1708428054592516"
          />
        </p>

        <br>
        <p><strong>Firebase</strong></p>
        <p>第三方主体：Google Ireland LTD</p>
        <p>使用目的：广告监测、归因与反作弊</p>
        <p>
          数据类型：设备标识符（IDFA）、国家/地区代码、设备信息、设备类型/模式、语言代码、用户区域设置、用户操作信息、系统事件或错误、地理位置、首选语言等
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://firebase.google.com/docs/analytics?hl=zh-cn"
            text="https://firebase.google.com/docs/analytics?hl=zh-cn"
          />
        </p>

        <br>
        <p><strong>Zendesk</strong></p>
        <p>第三方主体: Zendesk Inc.</p>
        <p>使用目的: 提供游戏内置的客户支持服务</p>
        <p>
          数据类型: IP地址、UID、设备识别码、<strong>其他您主动提供的个人信息等</strong>
        </p>
        <p>处理方法: 采取端到端、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网:
          <ExternalLink
            link="https://www.zendesk.tw/company/agreements-and-terms/privacy-notice/"
            text="https://www.zendesk.tw/company/agreements-and-terms/privacy-notice/"
          />
        </p>

        <br>
        <p><strong>Unity 3D</strong></p>
        <p>第三方主体：Unity Technologies ApS</p>
        <p>使用目的：展示3D人物形象时，提供基础动画功能</p>
        <p>
          数据类型：网络状态、手机状态和身份、设备标识符（Android如IMEI、设备序列号、Android ID）、移动网络运营商、进程信息权限、应用列表信息、传感器信息
        </p>
        <p>处理方法：仅在用户设备上本地处理，无传输</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://unity3d.com/legal/privacy-policy"
            text="https://unity3d.com/legal/privacy-policy"
          />
        </p>

        <br>
        <p><strong>支付宝 SDK</strong></p>
        <p>第三方主体：支付宝（杭州）信息技术有限公司</p>
        <p>使用目的：帮助用户在应用内使用支付宝支付</p>
        <p>
          数据类型：Android ID、OAID、SSID、BSSID；系统设置、系统属性、设备型号、设备品牌、操作系统；IP 地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 参数、Wi-Fi 列表、传感器列表、应用运行列表、应用安装列表
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://opendocs.alipay.com/open/54/01g6qm"
            text="https://opendocs.alipay.com/open/54/01g6qm"
          />
        </p>

        <br>
        <p><strong>微信支付 SDK</strong></p>
        <p>第三方主体：财付通支付科技有限公司</p>
        <p>使用目的：帮助用户在应用内使用微信支付</p>
        <p>
          数据类型：设备标识信息（MAC/Android ID/IMEI/IMSI/硬件序列号）、IP地址、存储权限、WiFi信息、订单金额
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://posts.tenpay.com/posts/18ed0968618e3db204d4931651708953.html"
            text="https://posts.tenpay.com/posts/18ed0968618e3db204d4931651708953.html"
          />
        </p>

        <br>
        <p><strong>微信开放 SDK</strong></p>
        <p>第三方主体：深圳市腾讯计算机系统有限公司</p>
        <p>使用目的：帮助用户分享内容至微信客户端、使用微信支付</p>
        <p>
          涉及个人信息类型：设备标识信息（MAC/Android ID/IMEI/IMSI/硬件序列号/OAID/IDFA）、IP地址、存储权限 、剪切板权限、设备加速器（如重力感应设备）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8"
            text="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8"
          />
        </p>

        <br>
        <p><strong>华为游戏联运 SDK</strong></p>
        <p>第三方主体：华为软件技术有限公司</p>
        <p>
          使用目的：提供账号登录服务、支付服务、用于系统能力评估、分析服务质量、服务改进及优化
        </p>
        <p>
          数据类型：设备标识信息（IMEI/硬件设备ID/UDID/EMUI）、设备信息（设备类型、操作系统信息、应用的基本信息）、应用软件列表、应用运行进程、账号信息、订单信息、退款记录、优惠券信息、实名认证信息、用户年龄、华为账号信息、产品品牌、操作系统信息、通用硬件设备ID、设备类型、国家码、应用包名、应用版本、SDK版本号、渠道号、SDK名称、应用基本信息、应用下载渠道、应用使用记录、应用功能设置、游戏内行为事件、游戏时长、操作系统、操作系统版本、系统语言、厂商、屏幕高度、屏幕宽度等
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://privacy.consumer.huawei.com/legal/jogs/privacy-statement.htm?code=CN&amp;branchid=1&amp;language=zh_Hans_CN"
            text="https://privacy.consumer.huawei.com/legal/jogs/privacy-statement.htm?code=CN&amp;branchid=1&amp;language=zh_Hans_CN"
          />
        </p>

        <br>
        <p><strong>荣耀游戏联运 SDK</strong></p>
        <p>第三方主体：深圳荣耀软件技术有限公司</p>
        <p>使用目的：提供账号登录服务、支付服务</p>
        <p>
          数据类型：用户姓名、<strong>身份证号（个人敏感信息）、</strong>荣耀帐号信息（Open
          ID、udid、Access Token）、设备信息（设备型号、语言设置、设备操作系统版本、ROM
          版本、国家码）、应用信息（应用版本号、应用标识符、插件版本号、网络类型、渠道号）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://developer.hihonor.com/cn/doc/guides/101153"
            text="https://developer.hihonor.com/cn/doc/guides/101153"
          />
        </p>

        <br>
        <p><strong>VIVO游戏联运 SDK</strong></p>
        <p>第三方主体：维沃移动通信有限公司</p>
        <p>使用目的：提供账号登录服务、支付服务</p>
        <p>
          数据类型：设备信息（IMEI/Emmcid/USFD/OAID/VAID/AAID），版本号、版本名称、联网方式、应用信息（非必要）、<strong>身份证信息（非必要 ，个人敏感信息）</strong>
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://dev.vivo.com.cn/documentCenter/doc/548"
            text="https://dev.vivo.com.cn/documentCenter/doc/548"
          />
        </p>

        <br>
        <p><strong>OPPO游戏联运 SDK</strong></p>
        <p>第三方主体：广东欢太科技有限公司</p>
        <p>使用目的：提供账号登录服务、支付服务</p>
        <p>
          数据类型：<strong>身份证号（个人敏感信息）</strong>、姓名、手机号、游戏数据（游戏使用时间、游戏等级、关卡情况）、应用进程列表信息、游戏内的消费记录、设备名称、设备型号、设备识别码、手机型号、MAC地址、IMEI、序列号、IP
          地址、操作系统版本
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://ie-activity-cn.heytapimage.com/ie-activity/staticActivity/kpmYVr/htmls/kpmYVr.html?bizType=ie&amp;actId=10001811&amp;c=0"
            text="https://ie-activity-cn.heytapimage.com/ie-activity/staticActivity/kpmYVr/htmls/kpmYVr.html?bizType=ie&amp;actId=10001811&amp;c=0"
          />
        </p>

        <br>
        <p><strong>小米游戏联运 SDK</strong></p>
        <p>第三方主体：北京瓦力网络科技有限公司</p>
        <p>使用目的：提供账号登录服务、支付服务</p>
        <p>
          数据类型：<strong>身份证号/照片（个人敏感信息）</strong>、姓名、手机号码、设备标识信息（OAID/FUID/Android
          ID/IMEI/IMSI/Mac地址）、设备信息(设备厂商/型号/归属地/序列号/系统版本/储存情况)、国家或地区、屏幕分辨率、像素密度、Android系统、SDK版本、游戏服务版本号、WIFI
          的
          SSID/BSSID、MIUI版本号、IP地址、第三方账号信息（用于实现登录账号）、游戏ID、MIUI版本、游戏安装来源、当前网络类型等
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://privacy.mi.com/xiaomigame-sdk/zh_CN/"
            text="https://privacy.mi.com/xiaomigame-sdk/zh_CN/"
          />
        </p>

        <br>
        <p><strong>233游戏联运 SDK</strong></p>
        <p>第三方主体：北京龙威互动科技有限公司及其关联公司</p>
        <p>使用目的：提供账号登录服务、支付服务</p>
        <p>
          数据类型：<strong>身份证号（敏感个人信息）</strong>、姓名、设备信息（IMEI、IMSI、Android
          ID、MAC地址）、设备安装列表、设备品牌、型号、软件系统版本、分辨率、网络信号强度、设备语言、设备序列号、网络日志信息、使用软件及相关服务的频率、崩溃数据、总体安装、使用情况、性能数据
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://doc.233leyuan.com/protocol/233%E4%B9%90%E5%9B%AD%E5%86%85%E8%B4%ADSDK%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.html"
            text="https://doc.233leyuan.com/protocol/233%E4%B9%90%E5%9B%AD%E5%86%85%E8%B4%ADSDK%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.html"
          />
        </p>

        <br>
        <p><strong>九游游戏联运 SDK</strong></p>
        <p>第三方主体：广州爱九游信息技术有限公司</p>
        <p>使用目的：提供账号登录服务、支付服务</p>
        <p>
          数据类型：设备标识信息（Android
          ID/IMEI/IMSI/MAC/OAID）、设备信息（设备型号/操作系统版本/设备设置/网络设备信息/设备环境信息）、设备所在位置信息（例如IP地址、GPS位置以及能够提供相关信息的WLAN
          接入点、基站等传感器信息）、剪切板信息、应用安装列表、当前运行应用进程、无线网络信息、读取用户账号、设备状态、IP地址、BSSID、SSID、移动运营商名称
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://open.9game.cn/ngprivacy"
            text="https://open.9game.cn/ngprivacy"
          />
        </p>

        <br>
        <p><strong>快手游戏联运 SDK</strong></p>
        <p>第三方主体：北京慕远科技有限公司</p>
        <p>使用目的：提供账号登录服务、支付服务</p>
        <p>
          数据类型：<strong>身份证号（敏感个人信息）</strong>、姓名、手机号、设备标识信息（IMEI/ICCID/IMSI/MAC地址）、设备信息（设备ID/设备序列号/设备名称/设备类型和版本/系统版本）、IP地址、软件安装列表、网络状态、存储权限
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://www.gamekuaishou.com/privacy"
            text="https://www.gamekuaishou.com/privacy"
          />
        </p>

        <br>
        <p><strong>抖音游戏联运 SDK</strong></p>
        <p>第三方主体：北京微播视界科技有限公司</p>
        <p>使用目的：提供账号、实名、支付等游戏联运服务</p>
        <p>
          数据类型：设备标识符（Android如IMEI/MEID、IMSI、OAID、GAID、Android
          ID、硬件序列号（Serial Number）、SIM卡信息（ICCID）、iOS如IDFV、IDFA、device
          name）、MAC地址、IP
          地址、位置信息（如WLAN接入点、蓝牙）、设备传感器信息、网络信息、设备信息、运行中的进程信息，头像、昵称、手机号、操作系统、User
          Agent，短视频分享记录（图片、视频等内容）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://sf1-cdn-tos.douyinstatic.com/obj/ies-hotsoon-draft/ttgame/b93a991b-5cd9-46f9-bf33-b142f7226d02.html"
            text="https://sf1-cdn-tos.douyinstatic.com/obj/ies-hotsoon-draft/ttgame/b93a991b-5cd9-46f9-bf33-b142f7226d02.html"
          />
        </p>

        <br>
        <p><strong>Tobid SDK</strong></p>
        <p>第三方主体：北京创致科技有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型： 设备标识符（IDFA）；设备品牌、名称、型号；设备IP、UA、WIFI
          SSID/BSSID，
          >设备分辨率、宽高、存储空间、设备剩余磁盘大小，手机运营商、网络状态、系统启动时间、电池状态、蓝牙名称，设备语言、时区、国家代码，操作系统版本、系统更新时间，设备传感器信息，地理位置
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://doc.sigmob.com/ToBid%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97/%E5%90%88%E8%A7%84%E6%9D%A1%E6%AC%BE/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96/"
            text="https://doc.sigmob.com/ToBid%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97/%E5%90%88%E8%A7%84%E6%9D%A1%E6%AC%BE/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96/"
          />
        </p>

        <br>
        <p><strong>Sigmob SDK</strong></p>
        <p>第三方主体：北京创智汇聚科技有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：设备标识符（IDFA）、设备品牌、名称、型号、设备IP、UA、WIFI
          SSID/BSSID、设备分辨率、宽高、存储空间、设备剩余磁盘大小、手机运营商、网络状态、系统启动时间、电池状态、蓝牙名称、设备语言、时区、国家代码、操作系统版本、系统更新时间、设备传感器信息、地理位置
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://doc.sigmob.com/Sigmob%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97/%E5%90%88%E8%A7%84%E6%9D%A1%E6%AC%BE/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96/"
            text="https://doc.sigmob.com/Sigmob%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97/%E5%90%88%E8%A7%84%E6%9D%A1%E6%AC%BE/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96/"
          />
        </p>

        <br>
        <p><strong>腾讯广告/优量汇 SDK</strong></p>
        <p>第三方主体：腾讯科技（深圳）有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：位置信息、设备制造商、品牌、设备型号、操作系统版本、屏幕分辨率、屏幕方向、屏幕DPI、IP地址、时区、网络类型、运营商、设备名称、系统启动时间、系统语言、磁盘空间、设备姿态、压力传感器等基础信息、设备标识符（IDFV、
          IDFA）、应用信息（宿主应用的包名、版本号、应用安装信息）、使用数据、产品交互数据、广告数据（如展示、点击、转化广告数据）、诊断数据（如崩溃数据、性能数据）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://e.qq.com/dev/help_detail.html?cid=2005&amp;pid=5983"
            text="https://e.qq.com/dev/help_detail.html?cid=2005&amp;pid=5983"
          />
        </p>

        <br>
        <p><strong>穿山甲广告 SDK</strong></p>
        <p>第三方主体：湖北今日头条科技有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：设备信息（设备品牌、型号、软件系统版本、分辨率、网络信号强度、IP地址、设备语言等基础信息、手机系统重启时间、磁盘总空间、系统总内存空间、CPU数目等基础信息、IDFV、无线网SSID名称、WiFi路由器MAC地址/BSSID、设备的MAC地址、设备标识符IDFA）、传感器信息（加速度传感器、陀螺仪传感器）、广告信息（对广告的展示、点击及转化等交互数据）、位置信息、性能数据、崩溃数据、运营商信息、设备时区、sim卡信息
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://www.csjplatform.com/privacy"
            text="https://www.csjplatform.com/privacy"
          />
        </p>

        <br>
        <p>
          <strong>快手广告 SDK</strong>
        </p>
        <p>第三方主体：北京快手广告有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：基础信息（设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息等基础信息）、设备标识（IMEI、MEID、OAID、IMSI、ICCID）、位置信息（IP
          地址、MAC 地址、<strong>GPS 位置信息</strong>、基站信息、WIFI
          信息）、应用信息（应用安装列表）、其他信息（传感器信息、sim卡激活信息）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://u.kuaishou.com/home/detail/1290"
            text="https://u.kuaishou.com/home/detail/1290"
          />
        </p>

        <br>
        <p>
          <strong>百度广告 SDK</strong>
        </p>
        <p>第三方主体：北京百度网讯科技有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：设备信息（设备品牌、型号、软件系统版本、分辨率、网络信号强度、传感器信息，磁盘总空间、系统总内存空间、手机重启信息、手机系统更新时间等基础信息、OAID、屏幕宽高，屏幕像素密度，系统版本号，设备厂商，设备型号，手机运营商，手机网络状态，设备剩余存储空间，手机重启时间和更新时间）、日志信息、开发者应用信息（应用包名、应用前后台状态）、设备标识符（IMEI、IMSI、IDFA）、位置信息
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://union.baidu.com/bqt/#/legal/policies"
            text="https://union.baidu.com/bqt/#/legal/policies"
          />
        </p>

        <br>
        <p>
          <strong>Unity广告 SDK</strong>
        </p>
        <p>第三方主体：Unity Technologies</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：设备信息、用户年龄、设备标识符（IDFA）、IP地址（城市和国家）、日志信息
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://unity.com/cn/legal/privacy-policy"
            text="https://unity.com/cn/legal/privacy-policy"
          />
        </p>

        <br>
        <p>
          <strong>Mintegral广告 SDK</strong>
        </p>
        <p>第三方主体：Mintegral International Limited</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：位置信息（IP地址、国家、时区和区域设置）、设备标识符（IMEI、OAID、IDFV、GAID）、设备信息（设备品牌、设备型号、操作系统信息、设备类型、屏幕信息、电池信息、设备体积和字体大小、是否是模拟器、网络信息、硬件信息、设备信息传感器、广告跟踪状态、系统启动时间、设备用户名、存储容量信息）、应用信息（应用程序版本及特性、SDK版本、已安装应用程序列表）、互动信息（查看或点击广告的频次等）、其他（时间戳、设备崩溃、系统活动、请求时间、引用
          的URL、用户代理等）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://www.mintegral.com/en/privacy"
            text="https://www.mintegral.com/en/privacy"
          />
        </p>

        <br>
        <p>
          <strong>Admob广告 SDK</strong>
        </p>
        <p>第三方主体：GOOGLE ASIA PACIFIC</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：设备信息、设备标识符（IDFA、IMEI、IDFV）、浏览器类型、设备类型、操作系统、移动网络信息（运营商名称、电话号码）、应用版本号、应用互动信息、IP
          地址、崩溃报告、系统活动、请求时间、引荐来源网址、用户年龄
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://policies.google.com/privacy?hl=zh&amp;gl=CN"
            text="https://policies.google.com/privacy?hl=zh&amp;gl=CN"
          />
        </p>

        <br>
        <p><strong>华为广告/鲸鸿动能 SDK</strong></p>
        <p>第三方主体：华为软件技术有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：设备信息（广告标识符OAID、设备标识符IMEI/Android
          ID、设备品牌、型号、传感器信息、系统基本信息\操作系统信息）、网络信息（网络信息\运营商、网络连接状态）、位置信息（大致位置信息\WLAN信息、蓝牙信息）、应用信息（应用基本信息、应用安装列表）、广告交互行为（广告互动记录，如曝光、点击、转化等信息）、上下文信息（上下文实时信息（模块栏目名称、文章标题、图片等信息）、经纬度信息（仅用于单次请求、不存储）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://privacy.consumer.huawei.com/legal/ads/privacy-statement.htm?&amp;code=CN&amp;language=zh-CN&amp;branchid=0&amp;contenttag=default"
            text="https://privacy.consumer.huawei.com/legal/ads/privacy-statement.htm?&amp;code=CN&amp;language=zh-CN&amp;branchid=0&amp;contenttag=default"
          />
        </p>

        <br>
        <p><strong>荣耀广告 SDK</strong></p>
        <p>第三方主体：深圳荣耀软件技术有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：设备信息：设备品牌、设备型号、广告标识符（OAID）、系统基本信息（操作系统、系统设置、设备使用信息）；网络信息：网络信息（网络连接状态、IP
          信息、Wi-Fi
          信息、运营商信息）；应用信息：应用基本信息、应用安装列表（可选）；应用使用信息：用户互动信息（曝光、点击、下载等转化信息）；上下文信息：上下文实时信息（页面主题、文章或视频标题、文章或图片链接等）；位置信息：精确位置信息（可选）、粗略位置信息（可选）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://developer.honor.com/cn/docs/20030/guides/sdk-privacy-statement"
            text="https://developer.honor.com/cn/docs/20030/guides/sdk-privacy-statement"
          />
        </p>

        <br>
        <p><strong>VIVO广告 SDK</strong></p>
        <p>第三方主体：广东天宸网络科技有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：设备信息（设备制造商，设备型号，系统版本，传感器信息（如加速度、重力、陀螺仪传感器））；网络信息（IP地址，联网方式，运营商信息，网络连接状态， SSID，BSSID）；标识符信息（IMEI（仅限安卓9及以前版本），IMSI，OAID，VAID，MAC地址，Android_ID，ICCID，SN号）；应用信息（应用名称，应用包名，应用版本号，软件安装列表）；个人位置信息（粗略定位信息）；应用使用信息（广告交互行为（广告互动记录，如曝光、点击、转化等信息））；诊断数据（崩溃数据、性能数据）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://adnet.vivo.com.cn/home/faq/18"
            text="https://adnet.vivo.com.cn/home/faq/18"
          />
        </p>

        <br>
        <p><strong>OPPO广告 SDK</strong></p>
        <p>第三方主体：广东欢太科技有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：IP地址、已安装应用列表、软件版本号、软件签名、软件包名、设备连接信息（电信运营商、网络环境）、设备属性信息（设备型号、操作系统版本、设备地理位置、IMEI、OpenID、设备SN码）、MAC地址、Wi-Fi信息（SSID\BSSID\信号强度\连接情况）、电池电量、充电状态、充电方式、内存情况（总内存、可用内存）、存储情况（总存储、可用存储）、CPU信息、Cookie（Android_ID、Cookie时间戳、IP地址）、IMSI、ICCID、Rom构建时间、响铃模式、屏幕亮度、SIM卡槽数、激活状态SIM卡数量、传感器信息（陀螺仪、加速度传感器、重力传感器）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://u.oppomobile.com/main/privacy.html"
            text="https://u.oppomobile.com/main/privacy.html"
          />
        </p>

        <br>
        <p><strong>小米广告 SDK</strong></p>
        <p>第三方主体：深圳小米信息技术有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：设备标识符(Android
          ID/IMEI/IMSI/OAID/AAID/UDID/VAID)、设备基础信息（设备型号、操作系统、IP地址、屏幕宽高、屏幕密度、Android版本号、SDK版本、MIUI版本号、
          MIUI版本名称、系统语言、UserAgent、手机地区、国家编码
          、国家名称）手机电量、是否充电、充电方式、屏幕亮度信息、usb状态、开机时长、Wi-Fi信息、cpu信息、存储信息、响铃模式、SIM卡槽数、激活状态SIM卡数量、网络信息（WiFi状态、移动运营商、网络类型、网络连接状态）、应用信息（应用包名、版本号、运行中App的进程列表）、广告信息（广告位ID、广告数量、对广告的展示、点击及转化等交互数据）、其他信息（个性化广告开关状态、是否为root环境、是否有调试状态、检测hook框架、无障碍服务是否开启、是否安装主流的hook工具包、系统编译的时间戳、设备ROM是否是出厂ROM）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://dev.mi.com/distribute/doc/details?pId=1688"
            text="https://dev.mi.com/distribute/doc/details?pId=1688"
          />
        </p>

        <br>
        <p><strong>233广告 SDK</strong></p>
        <p>第三方主体：北京龙威互动科技有限公司及其关联公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：设备/网络/系统信息、设备制造商、品牌、设备型号、操作系统版本、屏幕分辨率、屏幕方向、屏幕DPI、IP地址、时区、网络类型、运营商、连接的WIFI、标识符
          OAID、Android_ID
          、IMEI（可选）、应用信息（应用的包名、版本号、宿主应用的进程名称、运行状态、可疑行为、应用安装列表信息）、使用数据（产品交互数据）、广告数据（如展示、点击、转化广告数据）
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：
          <ExternalLink
            link="https://dev.233leyuan.com/#/doc/50138"
            text="https://dev.233leyuan.com/#/doc/50138"
          />
        </p>

        <br>
        <p><strong>九游广告 SDK</strong></p>
        <p>第三方主体：广州爱九游信息技术有限公司</p>
        <p>使用目的：广告投放、广告监测、归因与反作弊</p>
        <p>
          数据类型：设备标识信息（Android
          ID/IMEI/IMSI/MAC/OAID）、设备信息（设备型号/操作系统版本/设备设置/网络设备信息/设备环境信息）、设备所在位置信息（例如IP地址、GPS位置以及能够提供相关信息的WLAN
          接入点、基站等传感器信息）、剪切板信息、应用安装列表、当前运行应用进程、无线网络信息、读取用户账号、设备状态、IP地址、BSSID、SSID、移动运营商名称
        </p>
        <p>处理方法：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策或官网：<ExternalLink
            link="https://game.open.9game.cn/document/doc/detail/273"
            text="https://game.open.9game.cn/document/doc/detail/273"
          />
        </p>

        <br>
        <p><strong>华为推送 SDK</strong></p>
        <p>第三方主体：华为软件技术有限公司</p>
        <p>使用目的：推送消息</p>
        <p>
          数据类型：应用基本信息、设备标识符（AAID和Push Token）、设备信息（设备类型/设备型号/系统基本信息/系统设置信息）、应用运行进程
        </p>
        <p>处理方式：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策链接：<ExternalLink
          link="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177"
          text="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177"
        />
        </p>

        <br>
        <p><strong>VIVO推送 SDK</strong></p>
        <p>第三方主体：广东天宸网络科技有限公司</p>
        <p>使用目的：推送消息</p>
        <p>
          数据类型：设备标识信息（MEI、EmmCID、UFSID、Android、GUID、GAID、OPENID、VAID、OAID、RegID、加密的Android ID）、应用软件信息（用包名、版本号、APPID、安装、卸载、恢复出厂设置、运行状态）、设备制造商、网络相关信息（如IP地址、网络类型）、国家码、设备类型、推送SDK版本号、设备型号、操作系统版本、当前网络类型、消息发送结果、通知栏状态（如通知栏权限、用户点击行为）、锁屏状态（如是否锁屏，是否允许锁屏通知）
        </p>
        <p>处理方式：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策链接：<ExternalLink
          link="https://dev.vivo.com.cn/documentCenter/doc/652#w1-12075822"
          text="https://dev.vivo.com.cn/documentCenter/doc/652#w1-12075822"
        />
        </p>

        <br>
        <p><strong>华为分析 SDK</strong></p>
        <p>第三方主体：华为软件技术有限公司</p>
        <p>使用目的：用户行为分析</p>
        <p>
          数据类型：应用信息（应用基本信息、用户设置、应用使用信息），设备信息（设备标识符、AAID、OAID、操作系统、设备型号、设备硬件信息、系统设置、设备个性化配置、设备状态&运行记录），位置信息（IP归属地），网络信息（网络信息），应用设置信息（个性化广告开关）
        </p>
        <p>处理方式：采取去标识、加密等方式进行传输和处理</p>
        <p>
          隐私政策链接：<ExternalLink
          link="https://developer.huawei.com/consumer/cn/doc/HMSCore-Guides/android-sdk-data-security-0000001050745153"
          text="https://developer.huawei.com/consumer/cn/doc/HMSCore-Guides/android-sdk-data-security-0000001050745153"
        />
        </p>
      </div>
    </div>
  </Layout>
</template>

<script>
import ExternalLink from '@components/ExternalLink.vue'
import Header from '@components/Header.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,
    ExternalLink
  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
    }
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.ppcontainer {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 20px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: left;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    strong {
      color: #000;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 20px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  .scrollable {
    overflow-x: scroll;
    display: block;
    td {
      min-width: 240px;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
